import React from "react";
import { useRouter } from "next/router";
import useStore from "../store/store";
import en from "../locales/en";
import id from "../locales/id";
import "../styles/globals.css";

function MyApp({ Component, pageProps }) {
  const Layout = Component.Layout ? Component.Layout : React.Fragment;

  const router = useRouter();
  const { locale } = router;
  const setLocale = useStore((state) => state.setLocale);
  locale === "en" ? setLocale(en) : setLocale(id);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;
